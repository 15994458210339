import { useMemo, useCallback } from 'react';
import useGetBusiness from '@/api/hooks/useGetBusiness';
import { useBusinessId } from '@/utils/hooks/useBusinessId';
import type { BusinessApplicationProgressIdentifier as TabSection } from '@/types/business';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';

const useOnboardingSections = (
  isInjectBusinessId = false,
  injectBusinessId?: string | null,
) => {
  const businessApiId = useBusinessId();
  const businessId = isInjectBusinessId ? injectBusinessId : businessApiId;

  const { data: business } = useGetBusiness({ businessId });

  const { data: applicationProgress, refetch: refetchApplicationProgress } =
    useGetBusinessApplicationProgress(businessId ?? '', {
      enabled: businessId != null,
    });

  const sections = useMemo(
    () => applicationProgress?.sections ?? [],
    [applicationProgress],
  );

  const getSectionStatus = useCallback(
    (id: TabSection) => {
      const section = sections.find((section) => section.id === id);
      return section?.status;
    },
    [sections],
  );

  const checkSectionCompleted = useCallback(
    (id: TabSection) => {
      const sectionStatus = getSectionStatus(id);
      return sectionStatus === 'Completed';
    },
    [getSectionStatus],
  );

  return {
    businessId,
    business,
    sections,
    checkSectionCompleted,
    getSectionStatus,
    refetchApplicationProgress,
  };
};

export { TabSection, useOnboardingSections };
