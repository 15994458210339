import type { BusinessDocument, BusinessDocumentType } from '@/types/business';

import fetcher from '@/utils/fetcher';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getBusinessDocuments = <
  DocumentType extends BusinessDocumentType = BusinessDocumentType,
>({
  businessId,
  types,
  facilityId,
  ownerId,
  isGetAllTheSameType,
}: {
  businessId: string;
  types?: DocumentType[];
  facilityId?: string;
  ownerId?: string;
  isGetAllTheSameType?: boolean;
}): Promise<Array<BusinessDocument<DocumentType>>> => {
  let endpoint = `/business/${businessId}/documents`;

  const queryParams: string[] = [];

  if (facilityId) {
    queryParams.push(`facilityId=${facilityId}`);
  }

  if (ownerId) {
    queryParams.push(`ownerId=${ownerId}`);
  }

  if (types && types.length > 0) {
    queryParams.push(`types=${types.join(',')}`);
  }

  if (isGetAllTheSameType) {
    queryParams.push(`isGetAllTheSameType=true`);
  }

  if (queryParams.length > 0) {
    endpoint += `?${queryParams.join('&')}`;
  }

  return fetcher<Array<BusinessDocument<DocumentType>>>({
    endpoint,
  });
};

export default getBusinessDocuments;
