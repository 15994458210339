import { useQuery, UseQueryResult } from '@tanstack/react-query';
import type { BusinessApplicationProgress } from '@/types/business';
import type { QueryOptionsRequest } from '@/api/types';
import getBusinessApplicationProgress from './getBusinessApplicationProgress';

const useGetBusinessApplicationProgress = (
  businessId: string,
  queryOptions?: QueryOptionsRequest<BusinessApplicationProgress>,
): UseQueryResult<BusinessApplicationProgress, Error> => {
  return useQuery<BusinessApplicationProgress, Error>({
    queryKey: ['getBusinessApplicationProgress', businessId],
    queryFn: async () => await getBusinessApplicationProgress(businessId),
    ...queryOptions,
  });
};

export default useGetBusinessApplicationProgress;
