export enum ApplicationStatusEnum {
  REVIEW_ON_SYNCTERA = 'REVIEW_ON_SYNCTERA',
  REVIEW_INFO_REQUESTED = 'REVIEW_INFO_REQUESTED',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  ONBOARDING_NOT_FINISHED = 'ONBOARDING_NOT_FINISHED',
}

export interface GetBusinessApplicationStatusResponse {
  status: ApplicationStatusEnum;
  businessName: string;
  id: string;
}
