import { nanoid } from 'nanoid';
import { lazy } from 'react';

import { CardholderRoleRoutes } from '@/constants/router';
import type { SystemUserAuthResponse } from '@/types/user';

interface ICardholderRoleComponent {
  id: string;
  path?: string;
  component?: (prop?: SystemUserAuthResponse) => JSX.Element;
  children?: ICardholderRoleComponent[];
}

interface ICardholderRoleComponents {
  CardHolderDetail: ICardholderRoleComponent;
}

const LazyAuthorizedUsers = lazy(
  async () => await import('@pages/BankAccount/AuthorizedUsers'),
);

export const CardholderRoleComponents: ICardholderRoleComponents = {
  CardHolderDetail: {
    id: nanoid(),
    path: CardholderRoleRoutes.Detail,
    component: (prop) => <LazyAuthorizedUsers systemUser={prop} />,
  },
};
