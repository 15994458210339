import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import type { BusinessDetails } from '@/types/business';

interface MultipleBusinessStore {
  onboardingBusiness: BusinessDetails | null;
  setOnboardingBusiness: (business: BusinessDetails | null) => void;
}

export const useMultipleBusiness = create<MultipleBusinessStore>()(
  devtools(
    persist(
      (set) => ({
        onboardingBusiness: null,
        setOnboardingBusiness: (business: BusinessDetails | null) =>
          set({
            onboardingBusiness: business,
          }),
      }),
      {
        name: 'multi-business',
        getStorage: () => localStorage,
      },
    ),
  ),
);
