import { UseQueryResult } from '@tanstack/react-query';
import type { GetBusinessApplicationStatusResponse } from './type';
import type { QueryOptionsRequestCallback } from '@/api/types';
import { useQueryWithCallbacks } from '@/api/utils/useQueryWithCallbacks';
import getBusinessValidation from './getBusinessValidation';

const useGetBusinessValidation = (
  businessId: string,
  queryOptions?: QueryOptionsRequestCallback<GetBusinessApplicationStatusResponse>,
): UseQueryResult<GetBusinessApplicationStatusResponse, Error> => {
  return useQueryWithCallbacks<GetBusinessApplicationStatusResponse, Error>({
    queryKey: ['getBusinessValidation', businessId],
    queryFn: async () => await getBusinessValidation(businessId),
    ...queryOptions,
  });
};

export default useGetBusinessValidation;
