import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SystemRoleType } from '@pages/BankAccount/SystemUsers/__utils__/mock';

interface RoleSpecific {
  isAllowCreateAccount: boolean;
  isAllowCreateSecurePin: boolean;
  isAllowLogin: boolean;
  isAllowResetPassword: boolean;
  isAllowTwoFactorAuthentication: boolean;
  isAllowBusinessInfo: boolean;
  isAllowBusinessOwnershipFlow: boolean;
  isAllowAddAnotherBusinessNameFlow: boolean;
  isAllowMultiSocialMediaFlow: boolean;
  isAllowMrbForm: boolean;
  isAllowReviewPage: boolean;
  isAllowBankingActivityFlows: boolean;
  isAllowAtmServiceContractUpload: boolean;
  isAllowDebitProcessorContractUpload: boolean;
  isAllowMrbFlows: boolean;
  isAllowDocumentFinancialDocuments: boolean;
  isAllowDocumentUpdateCertAndLicense: boolean;
  isAllowDocumentBusinessDocuments: boolean;
  isAllowReviewAndSubmit: boolean;
  isAllowViewApplicationStatus: boolean;
  isAllowRunKYB: boolean;
  isAllowRunKYC: boolean;
  isAllowConfirmApplication: boolean;
  isAllowReceivedEmail: boolean;
  isAllowDocumentsAndRenewals: boolean;
  isAllowContactMultipleAccounts: boolean;
  isAllowAccounts: boolean;
  isAllowAddNewLocation: boolean;
  isAllowRequestCreateAdditionalUser: boolean;
  isAllowUpdatePointOfSaleData: boolean;
  isAllowNavigationMenuAndBar: boolean;
  isAllowViewAccountBalance: boolean;
  isAllowViewAccountInfo: boolean;
  isAllowViewTransactionSummaryList: boolean;
  isAllowFilterTransactions: boolean;
  isAllowWireTransfer: boolean;
  isAllowSearchTransactions: boolean;
  isAllowViewTransactionDetails: boolean;
  isAllowViewStatementsList: boolean;
  isAllowDownloadStatements: boolean;
  isAllowViewScheduledAchPaymentsList: boolean;
  isAllowViewPaymentsHistory: boolean;
  isAllowIssueDigitalDebitCards: boolean;
  isAllowDebitLimits: boolean;
  isAllowDepositCheck: boolean;
  isAllowDepositCash: boolean;
  isAllowSendAchPayment: boolean;
  isAllowScheduleRecurringAchPayment: boolean;
  isAllowManagePayees: boolean;
  isAllowIssuePhysicalDebitCards: boolean;
  isAllowKycCardHolders: boolean;
  isAllowRequestTransactionDispute: boolean;
  isAllowCancelAchPayment: boolean;
  isAllowFreezeLockCancelCard: boolean;
  isAllowSupportForm: boolean;
  isAllowGroupOfPayees: boolean;
  isAllowReviewCardHolders: boolean;
  isCardholderOnly: boolean;
}

interface RoleState extends RoleSpecific {
  roles: string[];
  setRoles: (roles: string[]) => void;
}

interface Roles {
  isAdmin: boolean;
  isOperating: boolean;
  isAccounting: boolean;
  isCardholder: boolean;
}

const getRoles = (roles: string[]): Roles => {
  const isAdmin = roles.includes(SystemRoleType.ADMINISTRATOR);
  const isOperating = roles.includes(SystemRoleType.OPERATING);
  const isAccounting = roles.includes(SystemRoleType.ACCOUNTING);
  const isCardholder = roles.includes(SystemRoleType.CARDHOLDER);

  return {
    isAdmin,
    isOperating,
    isAccounting,
    isCardholder,
  };
};

const getSpecificRoles = (roles: string[]): RoleSpecific => {
  const { isAdmin, isOperating, isAccounting, isCardholder } = getRoles(roles);

  const newRoles = {
    isAllowCreateAccount: isAdmin || isOperating || isAccounting,
    isAllowCreateSecurePin: isAdmin,
    isAllowLogin: isAdmin || isOperating || isAccounting,
    isAllowResetPassword: isAdmin || isOperating || isAccounting,
    isAllowTwoFactorAuthentication: isAdmin || isOperating || isAccounting,
    isAllowBusinessInfo: isAdmin,
    isAllowBusinessOwnershipFlow: isAdmin,
    isAllowAddAnotherBusinessNameFlow: isAdmin,
    isAllowMultiSocialMediaFlow: isAdmin,
    isAllowMrbForm: isAdmin,
    isAllowReviewPage: isAdmin,
    isAllowBankingActivityFlows: isAdmin,
    isAllowAtmServiceContractUpload: isAdmin,
    isAllowDebitProcessorContractUpload: isAdmin,
    isAllowMrbFlows: isAdmin,
    isAllowDocumentFinancialDocuments: isAdmin,
    isAllowDocumentUpdateCertAndLicense: isAdmin,
    isAllowDocumentBusinessDocuments: isAdmin,
    isAllowReviewAndSubmit: isAdmin,
    isAllowViewApplicationStatus: isAdmin,
    isAllowRunKYB: isAdmin,
    isAllowRunKYC: isAdmin,
    isAllowConfirmApplication: isAdmin,
    isAllowReceivedEmail: isAdmin,
    isAllowDocumentsAndRenewals: isAdmin,
    isAllowContactMultipleAccounts: isAdmin,
    isAllowAccounts: isAdmin,
    isAllowAddNewLocation: isAdmin,
    isAllowRequestCreateAdditionalUser: isAdmin || isOperating,
    isAllowUpdatePointOfSaleData: isOperating || isAccounting,
    isAllowNavigationMenuAndBar: isOperating || isAccounting,
    isAllowViewAccountBalance: isOperating || isAccounting,
    isAllowViewAccountInfo: isOperating || isAccounting,
    isAllowViewTransactionSummaryList: isOperating || isAccounting,
    isAllowFilterTransactions: isOperating || isAccounting,
    isAllowWireTransfer: isOperating,
    isAllowSearchTransactions: isOperating || isAccounting,
    isAllowViewTransactionDetails: isOperating || isAccounting,
    isAllowViewStatementsList: isOperating || isAccounting,
    isAllowDownloadStatements: isOperating || isAccounting,
    isAllowViewScheduledAchPaymentsList: isOperating || isAccounting,
    isAllowViewPaymentsHistory: isOperating || isAccounting,
    isAllowIssueDigitalDebitCards: isOperating,
    isAllowDebitLimits: isOperating,
    isAllowDepositCheck: isOperating,
    isAllowDepositCash: isOperating,
    isAllowSendAchPayment: isOperating,
    isAllowScheduleRecurringAchPayment: isOperating,
    isAllowManagePayees: isOperating,
    isAllowIssuePhysicalDebitCards: isOperating,
    isAllowKycCardHolders: isOperating,
    isAllowRequestTransactionDispute: isOperating,
    isAllowCancelAchPayment: isOperating,
    isAllowFreezeLockCancelCard: isOperating,
    isAllowSupportForm: isAdmin || isOperating || isAccounting,
    isAllowGroupOfPayees: isOperating,
    isAllowReviewCardHolders: isOperating,
    isCardholderOnly: !isAdmin && !isOperating && isCardholder,
  };

  return newRoles;
};

export const useRoles = create<RoleState>()(
  devtools((set) => ({
    // Role
    isAllowCreateAccount: false,
    isAllowCreateSecurePin: false,
    isAllowLogin: false,
    isAllowResetPassword: false,
    isAllowTwoFactorAuthentication: false,

    // Onboarding & Approval
    isAllowBusinessInfo: false,
    isAllowBusinessOwnershipFlow: false,
    isAllowAddAnotherBusinessNameFlow: false,
    isAllowMultiSocialMediaFlow: false,
    isAllowMrbForm: false,
    isAllowReviewPage: false,
    isAllowBankingActivityFlows: false,
    isAllowAtmServiceContractUpload: false,
    isAllowDebitProcessorContractUpload: false,
    isAllowMrbFlows: false,
    isAllowDocumentFinancialDocuments: false,
    isAllowDocumentUpdateCertAndLicense: false,
    isAllowDocumentBusinessDocuments: false,
    isAllowReviewAndSubmit: false,
    isAllowViewApplicationStatus: false,
    isAllowRunKYB: false,
    isAllowRunKYC: false,
    isAllowConfirmApplication: false,
    isAllowReceivedEmail: false,

    // Manage Business
    isAllowDocumentsAndRenewals: false,
    isAllowContactMultipleAccounts: false,
    isAllowAccounts: false,
    isAllowAddNewLocation: false,
    isAllowRequestCreateAdditionalUser: false,
    isAllowUpdatePointOfSaleData: false,

    // Banking
    isAllowNavigationMenuAndBar: false,
    isAllowViewAccountBalance: false,
    isAllowViewAccountInfo: false,
    isAllowViewTransactionSummaryList: false,
    isAllowFilterTransactions: false,
    isAllowWireTransfer: false,
    isAllowSearchTransactions: false,
    isAllowViewTransactionDetails: false,
    isAllowViewStatementsList: false,
    isAllowDownloadStatements: false,
    isAllowViewScheduledAchPaymentsList: false,
    isAllowViewPaymentsHistory: false,
    isAllowIssueDigitalDebitCards: false,
    isAllowDebitLimits: false,
    isAllowDepositCheck: false,
    isAllowDepositCash: false,
    isAllowSendAchPayment: false,
    isAllowScheduleRecurringAchPayment: false,
    isAllowManagePayees: false,
    isAllowIssuePhysicalDebitCards: false,
    isAllowKycCardHolders: false,
    isAllowRequestTransactionDispute: false,
    isAllowCancelAchPayment: false,
    isAllowFreezeLockCancelCard: false,
    isAllowSupportForm: false,
    isAllowGroupOfPayees: false,
    isAllowReviewCardHolders: false,
    isCardholderOnly: false,

    roles: [],
    setRoles: (roles) => set({ roles, ...getSpecificRoles(roles) }),
  })),
);
