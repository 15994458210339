import { Router, PublicRouter } from './router';

export const getLocationInfo = (path: string) => {
  return (
    {
      [Router.Onboarding.BusinessDetails.About]: {
        title: 'Onboarding: Business Details - About',
        page: Router.Onboarding.BusinessDetails.About,
      },
      [Router.Onboarding.BusinessDetails.Address]: {
        title: 'Onboarding: Business Details - Address',
        page: Router.Onboarding.BusinessDetails.Address,
      },
      [Router.Onboarding.BusinessDetails.Contact]: {
        title: 'Onboarding: Business Details - Contact',
        page: Router.Onboarding.BusinessDetails.Contact,
      },
      [Router.Onboarding.BusinessDetails.Review]: {
        title: 'Onboarding: Business Details - Review',
        page: Router.Onboarding.BusinessDetails.Review,
      },
      [Router.Onboarding.BusinessDetails.RequiredDocuments]: {
        title: 'Onboarding: Business Details - Required Documents',
        page: Router.Onboarding.BusinessDetails.RequiredDocuments,
      },
      [Router.Onboarding.BusinessOwnership.IndividualOwner]: {
        title: 'Onboarding: Business Ownership - Individual Owner',
        page: Router.Onboarding.BusinessOwnership.IndividualOwner,
      },
      [Router.Onboarding.BusinessOwnership.BusinessOwner]: {
        title: 'Onboarding: Business Ownership - Business Owner',
        page: Router.Onboarding.BusinessOwnership.BusinessOwner,
      },
      [Router.Onboarding.BusinessOwnership.ReviewOwners]: {
        title: 'Onboarding: Business Ownership - Review Owners',
        page: Router.Onboarding.BusinessOwnership.ReviewOwners,
      },
      [Router.Onboarding.BusinessOwnership.Start]: {
        title: 'Onboarding: Business Ownership - Start',
        page: Router.Onboarding.BusinessOwnership.Start,
      },

      [Router.Onboarding.BusinessDocuments.Upload]: {
        title: 'Onboarding: Business Documents',
        page: Router.Onboarding.BusinessDocuments.Upload,
      },

      [Router.Onboarding.BusinessOperatorDetails.Details]: {
        title: 'Onboarding: Business Operator Details',
        page: Router.Onboarding.BusinessOperatorDetails.Details,
      },

      [Router.Onboarding.FacilitiesLocations.LegalNames]: {
        title: 'Onboarding: Facilities - Legal Names',
        page: Router.Onboarding.FacilitiesLocations.LegalNames,
      },
      [Router.Onboarding.FacilitiesLocations.LicenseRequisite]: {
        title: 'Onboarding: Facilities - License Requisite',
        page: Router.Onboarding.FacilitiesLocations.LicenseRequisite,
      },
      [Router.Onboarding.FacilitiesLocations.LicensedFacilities]: {
        title: 'Onboarding: Facilities - Licensed Facilities',
        page: Router.Onboarding.FacilitiesLocations.LicensedFacilities,
      },
      [Router.Onboarding.FacilitiesLocations.NamesAreCorrect]: {
        title: 'Onboarding: Facilities - Names Are Correct',
        page: Router.Onboarding.FacilitiesLocations.NamesAreCorrect,
      },
      [Router.Onboarding.FacilitiesLocations.ReviewDetails]: {
        title: 'Onboarding: Facilities - Review Details',
        page: Router.Onboarding.FacilitiesLocations.ReviewDetails,
      },
      [Router.Onboarding.FacilitiesLocations.RequiredDocuments]: {
        title: 'Onboarding: Facilities - Required Documents',
        page: Router.Onboarding.FacilitiesLocations.RequiredDocuments,
      },

      [Router.Onboarding.Facilities.Details.Products]: {
        title: 'Onboarding: Facilities - Products',
        page: Router.Onboarding.Facilities.Details.Products,
      },
      [Router.Onboarding.Facilities.Details.Manager]: {
        title: 'Onboarding: Facilities - Manager',
        page: Router.Onboarding.Facilities.Details.Manager,
      },
      [Router.Onboarding.Facilities.Details.Protocols]: {
        title: 'Onboarding: Facilities - Protocols',
        page: Router.Onboarding.Facilities.Details.Protocols,
      },
      [Router.Onboarding.Facilities.Details.SoftwareLicense]: {
        title: 'Onboarding: Facilities - Software License',
        page: Router.Onboarding.Facilities.Details.SoftwareLicense,
      },
      [Router.Onboarding.Facilities.Details.Transportation]: {
        title: 'Onboarding: Facilities - Transportation',
        page: Router.Onboarding.Facilities.Details.Transportation,
      },
      [Router.Onboarding.Facilities.Details.Type]: {
        title: 'Onboarding: Facilities - Type',
        page: Router.Onboarding.Facilities.Details.Type,
      },
      [Router.Onboarding.Facilities.Details.Address]: {
        title: 'Onboarding: Facilities - Address',
        page: Router.Onboarding.Facilities.Details.Address,
      },

      [Router.Onboarding.Facilities.BusinessActivity.AcceptDebitCards]: {
        title: 'Onboarding: Facilities - Accept Debit Cards',
        page: Router.Onboarding.Facilities.BusinessActivity.AcceptDebitCards,
      },
      [Router.Onboarding.Facilities.BusinessActivity
        .AcceptOrTransmitWireTransfers]: {
        title: 'Onboarding: Facilities - Accept Or Transmit Wire Transfers',
        page: Router.Onboarding.Facilities.BusinessActivity,
      },
      [Router.Onboarding.Facilities.BusinessActivity.ArmoredCarServices]: {
        title: 'Onboarding: Facilities - Armored Car Services',
        page: Router.Onboarding.Facilities.BusinessActivity.ArmoredCarServices,
      },
      [Router.Onboarding.Facilities.BusinessActivity.ATMLocation]: {
        title: 'Onboarding: Facilities - ATM Location',
        page: Router.Onboarding.Facilities.BusinessActivity.ATMLocation,
      },
      [Router.Onboarding.Facilities.BankingActivity.PrimaryPurpose]: {
        title: 'Onboarding: Facilities - Primary Purpose',
        page: Router.Onboarding.Facilities.BankingActivity.PrimaryPurpose,
      },
      [Router.Onboarding.Facilities.BankingActivity.PrimaryBusiness]: {
        title: 'Onboarding: Facilities - Primary Business',
        page: Router.Onboarding.Facilities.BankingActivity.PrimaryBusiness,
      },
      [Router.Onboarding.Facilities.BankingActivity.BankingActivityDetail]: {
        title: 'Onboarding: Facilities - Banking Activity Detail',
        page: Router.Onboarding.Facilities.BankingActivity
          .BankingActivityDetail,
      },

      [Router.Onboarding.Facilities.LocationReview.ActivityTierOne]: {
        title: 'Onboarding: Facilities - Tier One Review',
        page: Router.Onboarding.Facilities.LocationReview.ActivityTierOne,
      },
      [Router.Onboarding.Facilities.LocationReview.ActivityTierTwo]: {
        title: 'Onboarding: Facilities - Tier Two Review',
        page: Router.Onboarding.Facilities.LocationReview.ActivityTierTwo,
      },

      [Router.Onboarding.Facilities.Documents.UploadDocuments]: {
        title: 'Onboarding: Facilities - Upload Location Documents',
        page: Router.Onboarding.Facilities.Documents.UploadDocuments,
      },

      [Router.Onboarding.ReviewAndSign.Submit]: {
        title: 'Onboarding: Review & Sign - Submit',
        page: Router.Onboarding.ReviewAndSign.Submit,
      },
      [Router.Onboarding.ReviewAndSign.Success]: {
        title: 'Onboarding: Review & Sign - Success',
        page: Router.Onboarding.ReviewAndSign.Success,
      },

      // Dashboard
      [Router.BankAccount.Dashboard]: {
        title: 'Dashboard',
        page: Router.BankAccount.Dashboard,
      },
      // Account Overview
      [Router.BankAccount.Overview]: {
        title: 'Account Overview',
        page: Router.BankAccount.Overview,
      },
      // Payments & Payees
      [Router.BankAccount.PaymentsAndPayees]: {
        title: 'Payments & Payees',
        page: Router.BankAccount.PaymentsAndPayees,
      },
      [Router.BankAccount.ManageScheduledPayments]: {
        title: 'Payments & Payees: Manage Scheduled Payments',
        page: Router.BankAccount.ManageScheduledPayments,
      },
      [Router.BankAccount.PayAch]: {
        title: 'Payments & Payees: ACH - Initiate ACH Transfer',
        page: Router.BankAccount.PayAch,
      },
      [Router.BankAccount.ReviewAch]: {
        title: 'Payments & Payees: ACH - Review ACH Transfer',
        page: Router.BankAccount.ReviewAch,
      },
      [Router.BankAccount.SuccessAch]: {
        title: 'Payments & Payees: ACH - Initiate ACH Transfer Success',
        page: Router.BankAccount.SuccessAch,
      },
      [Router.BankAccount.PayWire]: {
        title: 'Payments & Payees: Wire - Initiate Wire Transfer',
        page: Router.BankAccount.PayWire,
      },
      [Router.BankAccount.ReviewWire]: {
        title: 'Payments & Payees: Wire - Review Wire Transfer',
        page: Router.BankAccount.ReviewWire,
      },
      [Router.BankAccount.SuccessWire]: {
        title: 'Payments & Payees: Wire - Initiate Wire Transfer Success',
        page: Router.BankAccount.SuccessWire,
      },
      [Router.BankAccount.RequestCash]: {
        title: 'Payments & Payees: Request Cash',
        page: Router.BankAccount.RequestCash,
      },
      [Router.BankAccount.RequestCashComplete]: {
        title: 'Payments & Payees: Request Cash Complete',
        page: Router.BankAccount.RequestCash,
      },
      [Router.BankAccount.DepositCheck]: {
        title: 'Payments & Payees: Deposit Check',
        page: Router.BankAccount.DepositCheck,
      },
      [Router.BankAccount.CompleteDepositCheck]: {
        title: 'Payments & Payees: Deposit Check Complete',
        page: Router.BankAccount.CompleteDepositCheck,
      },
      // Authorized Users
      [Router.BankAccount.AuthorizedUsers]: {
        title: 'Authorized Users',
        page: Router.BankAccount.AuthorizedUsers,
      },
      [Router.BankAccount.Cardholders]: {
        title: 'Authorized Users: Cardholders',
        page: Router.BankAccount.Cardholders,
      },
      [Router.BankAccount.DebitLimits]: {
        title: 'Authorized Users: Debit Limits',
        page: Router.BankAccount.DebitLimits,
      },
      // System Users
      [Router.BankAccount.SystemUsers]: {
        title: 'System Users: Users',
        page: Router.BankAccount.SystemUsers,
      },
      [Router.BankAccount.SystemRoleType]: {
        title: 'System Users: Roles',
        page: Router.BankAccount.SystemRoleType,
      },
      [Router.BankAccount.SystemRolesDetail]: {
        title: 'System Users: Roles Detail',
        page: Router.BankAccount.SystemRolesDetail,
      },
      // Business Details
      [Router.BankAccount.BusinessDetails]: {
        title: 'Business Details',
        page: Router.BankAccount.BusinessDetails,
      },
      // Business Details
      [Router.BankAccount.LocationDetails]: {
        title: 'Location Details',
        page: Router.BankAccount.LocationDetails,
      },
      // Enrollment
      [Router.BankAccount.PaymentSystemEnrollment]: {
        title: 'Payment System Enrollment',
        page: Router.BankAccount.PaymentSystemEnrollment,
      },
      // New Location
      [Router.BankAccount.FacilitiesLocations.LegalNames]: {
        title: 'New Location: Facilities - Legal Names',
        page: Router.BankAccount.FacilitiesLocations.LegalNames,
      },
      [Router.BankAccount.FacilitiesLocations.AddNewFacility]: {
        title: 'New Location: Facilities - Add New Facility',
        page: Router.BankAccount.FacilitiesLocations.AddNewFacility,
      },

      [Router.BankAccount.FacilitiesLocations.LicenseRequisite]: {
        title: 'New Location: Facilities - License Requisite',
        page: Router.BankAccount.FacilitiesLocations.LicenseRequisite,
      },
      [Router.BankAccount.FacilitiesLocations.LicensedFacilities]: {
        title: 'New Location: Facilities - Licensed Facilities',
        page: Router.BankAccount.FacilitiesLocations.LicensedFacilities,
      },
      [Router.BankAccount.FacilitiesLocations.NamesAreCorrect]: {
        title: 'New Location: Facilities - NamesAreCorrect',
        page: Router.BankAccount.FacilitiesLocations.NamesAreCorrect,
      },
      [Router.BankAccount.FacilitiesLocations.ReviewDetails]: {
        title: 'New Location: Facilities - Review Details',
        page: Router.BankAccount.FacilitiesLocations.ReviewDetails,
      },
      [Router.BankAccount.FacilitiesLocations.RequiredDocuments]: {
        title: 'New Location: Facilities - Required Documents',
        page: Router.BankAccount.FacilitiesLocations.RequiredDocuments,
      },
      [Router.BankAccount.Facilities.Details.Products]: {
        title: 'New Location: Facilities - Products',
        page: Router.BankAccount.Facilities.Details.Products,
      },
      [Router.BankAccount.Facilities.Details.Manager]: {
        title: 'New Location: Facilities - Manager',
        page: Router.BankAccount.Facilities.Details.Manager,
      },
      [Router.BankAccount.Facilities.Details.Protocols]: {
        title: 'New Location: Facilities - Protocols',
        page: Router.BankAccount.Facilities.Details.Protocols,
      },
      [Router.BankAccount.Facilities.Details.SoftwareLicense]: {
        title: 'New Location: Facilities - SoftwareLicense',
        page: Router.BankAccount.Facilities.Details.SoftwareLicense,
      },
      [Router.BankAccount.Facilities.Details.Transportation]: {
        title: 'New Location: Facilities - Transportation',
        page: Router.BankAccount.Facilities.Details.Transportation,
      },
      [Router.BankAccount.Facilities.Details.Type]: {
        title: 'New Location: Facilities - Type',
        page: Router.BankAccount.Facilities.Details.Type,
      },
      [Router.BankAccount.Facilities.Details.Address]: {
        title: 'New Location: Facilities - Address',
        page: Router.BankAccount.Facilities.Details.Address,
      },
      [Router.BankAccount.Facilities.BusinessActivity.AcceptDebitCards]: {
        title: 'New Location: Facilities - Accept Debit Cards',
        page: Router.BankAccount.Facilities.BusinessActivity.AcceptDebitCards,
      },
      [Router.BankAccount.Facilities.BusinessActivity
        .AcceptOrTransmitWireTransfers]: {
        title: 'New Location: Facilities - Accept Or Transmit Wire Transfers',
        page: Router.BankAccount.Facilities.BusinessActivity,
      },
      [Router.BankAccount.Facilities.BusinessActivity.ArmoredCarServices]: {
        title: 'New Location: Facilities - Armored Car Services',
        page: Router.BankAccount.Facilities.BusinessActivity.ArmoredCarServices,
      },
      [Router.BankAccount.Facilities.BusinessActivity.ATMLocation]: {
        title: 'New Location: Facilities - ATM Location',
        page: Router.BankAccount.Facilities.BusinessActivity.ATMLocation,
      },
      [Router.BankAccount.Facilities.BankingActivity.PrimaryPurpose]: {
        title: 'New Location: Facilities - Primary Purpose',
        page: Router.BankAccount.Facilities.BankingActivity.PrimaryPurpose,
      },
      [Router.BankAccount.Facilities.BankingActivity.PrimaryBusiness]: {
        title: 'New Location: Facilities - Primary Business',
        page: Router.BankAccount.Facilities.BankingActivity.PrimaryBusiness,
      },
      [Router.BankAccount.Facilities.BankingActivity.BankingActivityDetail]: {
        title: 'New Location: Facilities - Banking Activity Detail',
        page: Router.BankAccount.Facilities.BankingActivity
          .BankingActivityDetail,
      },
      [Router.BankAccount.Facilities.LocationReview.ActivityTierOne]: {
        title: 'New Location: Facilities - Tier One Review',
        page: Router.BankAccount.Facilities.LocationReview.ActivityTierOne,
      },
      [Router.BankAccount.Facilities.LocationReview.ActivityTierTwo]: {
        title: 'New Location: Facilities - Tier Two Review',
        page: Router.BankAccount.Facilities.LocationReview.ActivityTierTwo,
      },
      [Router.BankAccount.Facilities.Documents.UploadDocuments]: {
        title: 'New Location: Facilities - Upload Documents',
        page: Router.BankAccount.Facilities.Documents.UploadDocuments,
      },
      [Router.BankAccount.Facilities.Details.Review]: {
        title: 'New Location: Facilities - Details Review',
        page: Router.BankAccount.Facilities.Details.Review,
      },
      [Router.BankAccount.Facilities.BankingActivity.Review]: {
        title: 'New Location: Facilities - Banking Activity Review',
        page: Router.BankAccount.Facilities.BankingActivity.Review,
      },
      [Router.BankAccount.Facilities.BusinessActivity.Review]: {
        title: 'New Location: Facilities - Business Activity Review',
        page: Router.BankAccount.Facilities.BusinessActivity.Review,
      },

      [PublicRouter.ExpiredPassword]: {
        title: 'Public: Expired Password',
        page: PublicRouter.ExpiredPassword,
      },
      [PublicRouter.ExistedAccount]: {
        title: 'Public: Existed Account',
        page: PublicRouter.ExistedAccount,
      },
      [PublicRouter.EmailVerification]: {
        title: 'Public: Email Verification',
        page: PublicRouter.EmailVerification,
      },
      [Router.BankAccount.Success]: {
        title: 'Bank Account: Success',
        page: Router.BankAccount.Success,
      },
      [Router.BankAccount.Review]: {
        title: 'Bank Account: Review',
        page: Router.BankAccount.Review,
      },

      [Router.BankAccount.Reports]: {
        title: 'Bank Account: Reports',
        page: Router.BankAccount.Reports,
      },
      [Router.CardholderRole.Detail]: {
        title: 'Authorized Cardholders',
        page: Router.CardholderRole.Detail,
      },
    }[path] ?? { title: '', page: '' }
  );
};
