import { nanoid } from 'nanoid';
import { lazy } from 'react';

import { MultipleBusinessesRoutes } from '@/constants/router';
import type { SystemUserAuthResponse } from '@/types/user';

interface IMultipleBusinessesComponent {
  id: string;
  path?: string;
  component?: (prop?: SystemUserAuthResponse) => JSX.Element;
  children?: IMultipleBusinessesComponent[];
}

interface IMultipleBusinessesComponents {
  BusinessLogin: IMultipleBusinessesComponent;
  ManageBusinesses: IMultipleBusinessesComponent;
  ManageUsers: IMultipleBusinessesComponent;
  HistoryLog: IMultipleBusinessesComponent;
}

const LazyBusinessLogin = lazy(
  async () => await import('@pages/MultipleBusinesses/BusinessLogin'),
);

const LazyManageBusinesses = lazy(
  async () => await import('@pages/MultipleBusinesses/ManageBusinesses'),
);

const LazyManageUsers = lazy(
  async () => await import('@pages/MultipleBusinesses/ManageUsers'),
);

const LazyHistoryLog = lazy(
  async () => await import('@pages/MultipleBusinesses/HistoryLog'),
);

export const MultipleBusinessesComponents: IMultipleBusinessesComponents = {
  BusinessLogin: {
    id: nanoid(),
    path: MultipleBusinessesRoutes.BusinessLogin,
    component: () => <LazyBusinessLogin />,
  },
  ManageBusinesses: {
    id: nanoid(),
    path: MultipleBusinessesRoutes.ManageBusinesses,
    component: () => <LazyManageBusinesses />,
  },
  ManageUsers: {
    id: nanoid(),
    path: MultipleBusinessesRoutes.ManageUsers,
    component: () => <LazyManageUsers />,
  },
  HistoryLog: {
    id: nanoid(),
    path: MultipleBusinessesRoutes.HistoryLogs,
    component: () => <LazyHistoryLog />,
  },
};
