import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import type { TAuthenticatedUser, TGroupUser } from '@/types/user';

interface MultipleProfilesStore {
  userProfile: TAuthenticatedUser | null;
  groupProfile: TGroupUser | null;
  setUserProfile: (profile: TAuthenticatedUser | null) => void;
  setGroupProfile: (profile: TGroupUser) => void;
}

export const useMultipleProfiles = create<MultipleProfilesStore>()(
  devtools(
    persist(
      (set) => ({
        userProfile: null,
        groupProfile: null,
        setUserProfile: (profile: TAuthenticatedUser | null) =>
          set({
            userProfile: profile,
          }),
        setGroupProfile: (profile: TGroupUser) =>
          set({
            groupProfile: profile,
          }),
      }),
      {
        name: 'user-profiles',
        getStorage: () => localStorage,
      },
    ),
  ),
);
