import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { Router } from '@/constants/router';
import { navigationStep } from '@/features/ui/navigation';
import { StepNavigationButtonProps } from '@/components/Sidebar/SideBarNavigation/components/StepNavigation/StepNavigationButton';
import _ from 'lodash';

export type OnboardingStep = Record<string, StepNavigationButtonProps>;

interface NavigationState {
  isChangedTier: boolean;
  shouldGoBusinessDetailReview: boolean;
  currentFacilityPath: string;
  onboardingSteps: OnboardingStep;
  setCurrentFacilityPath: (path: string) => void;
  setOnboardingStep: (steps: OnboardingStep) => void;
  setShouldGoBusinessDetailReview: (
    shouldGoBusinessDetailReview: boolean,
  ) => void;
  setIsChangedTier: (isChangedTier: boolean) => void;
  resetOnboardingProgress: () => void;
}

const initOnboardingSteps = {
  [navigationStep.BusinessDetails]: {
    id: navigationStep.BusinessDetails,
    path: Router.Onboarding.BusinessDetails.About,
    childPaths: _.map(Router.Onboarding.BusinessDetails, (path) => path),
    text: 'Business Details',
    isCompleted: false,
  },
  [navigationStep.BusinessOperatorDetails]: {
    id: navigationStep.BusinessOperatorDetails,
    path: Router.Onboarding.BusinessOperatorDetails.Details,
    childPaths: _.map(
      Router.Onboarding.BusinessOperatorDetails,
      (path) => path,
    ),
    text: 'Business Operator Details',
    isCompleted: false,
  },
  [navigationStep.BusinessOwnership]: {
    id: navigationStep.BusinessOwnership,
    path: Router.Onboarding.BusinessOwnership.Start,
    childPaths: _.map(Router.Onboarding.BusinessOwnership, (path) => path),
    text: 'Business Ownership',
    isCompleted: false,
  },
  [navigationStep.Facilities]: {
    id: navigationStep.Facilities,
    path: Router.Onboarding.FacilitiesLocations.LicenseRequisite,
    childPaths: _.map(
      {
        ...Router.Onboarding.FacilitiesLocations,
        ...Router.Onboarding.Facilities.Details,
        ...Router.Onboarding.Facilities.BankingActivity,
        ...Router.Onboarding.Facilities.BusinessActivity,
        ...Router.Onboarding.Facilities.Documents,
      },
      (path) => path,
    ),
    text: 'Facilities',
    isCompleted: false,
  },
  [navigationStep.BusinessDocuments]: {
    id: navigationStep.BusinessDocuments,
    path: Router.Onboarding.BusinessDocuments.Upload,
    childPaths: _.map(Router.Onboarding.BusinessDocuments, (path) => path),
    text: 'Business Documents',
    isCompleted: false,
  },
  [navigationStep.ReviewAndSign]: {
    id: navigationStep.ReviewAndSign,
    path: Router.Onboarding.ReviewAndSign.Submit,
    childPaths: _.map(Router.Onboarding.ReviewAndSign, (path) => path),
    text: 'Review & Sign',
    isCompleted: false,
  },
};

export const useOnboardingProgress = create<NavigationState>()(
  devtools(
    persist(
      (set) => ({
        isChangedTier: false,
        shouldGoBusinessDetailReview: false,
        currentFacilityPath:
          Router.Onboarding.FacilitiesLocations.LicenseRequisite,
        onboardingSteps: initOnboardingSteps,
        setOnboardingStep: (updateSteps) =>
          set((state) => ({
            onboardingSteps: { ...state.onboardingSteps, ...updateSteps },
          })),
        setShouldGoBusinessDetailReview: (shouldGoBusinessDetailReview) =>
          set({ shouldGoBusinessDetailReview }),
        setIsChangedTier: (isChangedTier) => set({ isChangedTier }),
        setCurrentFacilityPath: (currentFacilityPath) =>
          set({ currentFacilityPath }),
        resetOnboardingProgress: () =>
          set({
            isChangedTier: false,
            currentFacilityPath:
              Router.Onboarding.FacilitiesLocations.LicenseRequisite,
            shouldGoBusinessDetailReview: false,
            onboardingSteps: initOnboardingSteps,
          }),
      }),
      {
        name: 'onboarding-steps',
        getStorage: () => localStorage,
      },
    ),
  ),
);
