import _omit from 'lodash/omit';
import type { UseQueryResult } from '@tanstack/react-query';
import { useQueryWithCallbacks } from '@/api/utils/useQueryWithCallbacks';
import type { QueryOptionsRequestCallback } from '@/api/types';

import type { TAuthenticatedUser } from '@/types/user';
import { useRoles } from '@/store/useRoles';
import { useMultipleProfiles } from '@/store/useMultipleProfiles';
import { EnumSystemRoleType } from '@/types/system-roles';
import getAuthenticatedUser from './getAuthenticatedUser';

const useAuthenticatedUser = (
  queryOptions?: QueryOptionsRequestCallback<TAuthenticatedUser> & {
    isEmailVerified?: boolean;
  },
): UseQueryResult<TAuthenticatedUser, Error> => {
  const setRoles = useRoles((state) => state.setRoles);
  const setGroupProfile = useMultipleProfiles((state) => state.setGroupProfile);
  const setUserProfile = useMultipleProfiles((state) => state.setUserProfile);

  const originalOptions = _omit(queryOptions, ['isEmailVerified']);

  return useQueryWithCallbacks<TAuthenticatedUser, Error>({
    queryKey: ['getAuthenticatedUser'],
    queryFn: async () =>
      await getAuthenticatedUser(queryOptions?.isEmailVerified),
    ...originalOptions,
    onSuccess: (data) => {
      const isSuperAdmin =
        data?.businessGroupRole === EnumSystemRoleType.SUPERADMIN;

      if (data?.businessGroupId) {
        setGroupProfile({ ...data, isSuperAdmin });
      } else if (data?.id) {
        setUserProfile({ ...data });
      }

      originalOptions?.onSuccess?.(data);
      setRoles(data?.roles ?? []);
    },
  });
};

export default useAuthenticatedUser;
