import type { TAuthenticatedUser } from '@/types/user';

import fetcher from '@/utils/fetcher';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getAuthenticatedUser = (
  isEmailVerified?: boolean,
): Promise<TAuthenticatedUser> =>
  fetcher<TAuthenticatedUser>({
    endpoint: `/users/authenticate${isEmailVerified ? `?isEmailVerified=true` : ''}`,
  });

export default getAuthenticatedUser;
