import { Auth0Provider, User } from '@auth0/auth0-react';

import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '@/constants/env';
import loginUserFetcher from '@/api/hooks/userAuthUser/loginUser';
import { useCallback } from 'react';
import { clearLocalStorage } from '@/utils/hooks/useAuth/useLogout';

interface AuthenticationProviderProps {
  children: React.ReactNode;
}

export function AuthenticationProvider(
  props: AuthenticationProviderProps,
): JSX.Element {
  const { children } = props;

  const onRedirectCallback = useCallback((_, user?: User) => {
    // clear previous user data
    clearLocalStorage();
    if (user?.email_verified) {
      void loginUserFetcher();
    }
  }, []);

  return (
    <Auth0Provider
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      useRefreshTokens
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: AUTH0_AUDIENCE,
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}
