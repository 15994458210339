import { NumericFormat, PatternFormat } from 'react-number-format';

import InputText from '@/components/Forms/InputText';

import {
  EIN_FORMAT,
  ENCRYPTED_EIN_FORMAT,
  ENCRYPTED_SSN_FORMAT,
  ENCRYPTED_SIN_FORMAT,
  SSN_FORMAT,
  SIN_FORMAT,
  US_PHONE_FORMAT,
} from '@/constants/misc';

import type { NumericMaskProps, PatternMaskProps } from '../types';
import { useEffect, useState } from 'react';

const EMPTY_MASK = '_';

export function SSNInput<Name extends string>(
  props: Omit<PatternMaskProps, 'type' | 'format'> & { name: Name },
): JSX.Element {
  const { value: rawValue, ...restOfProps } = props;

  const [encryptState, setEncryptState] = useState(false);
  const fieldValue = rawValue?.toString();
  const hasNoStoredValue = fieldValue?.length === 0;
  const formattedValue = fieldValue?.replace(/[*-]/g, '');
  const containsUnderscores = formattedValue?.includes('_');

  let valueToDisplay;
  const valueIsComingFromDatabase =
    formattedValue?.length === 4 && !containsUnderscores;

  if (valueIsComingFromDatabase) {
    valueToDisplay = `***-**-${formattedValue}`;
  } else {
    valueToDisplay = rawValue;
  }

  if (hasNoStoredValue) {
    valueToDisplay = '';
  }

  useEffect(() => {
    if (valueIsComingFromDatabase) {
      setEncryptState(true);
    }
  }, [valueIsComingFromDatabase]);

  useEffect(() => {
    if (!valueToDisplay) {
      setEncryptState(false);
    }
  }, [valueToDisplay]);

  return (
    <PatternFormat
      {...restOfProps}
      allowEmptyFormatting
      customInput={InputText}
      format={encryptState ? ENCRYPTED_SSN_FORMAT : SSN_FORMAT}
      mask={EMPTY_MASK}
      value={valueToDisplay}
      onChange={(event) => {
        const { value } = event.target;
        if (
          value.split('').filter((char) => char === '_').length === 1 ||
          value === '***-**-____'
        ) {
          setEncryptState(false);
        }
      }}
    />
  );
}

export function SINInput<Name extends string>(
  props: Omit<PatternMaskProps, 'type' | 'format'> & { name: Name },
): JSX.Element {
  const { value: rawValue, ...restOfProps } = props;

  const [encryptState, setEncryptState] = useState(false);
  const fieldValue = rawValue?.toString();
  const hasNoStoredValue = fieldValue?.length === 0;
  const formattedValue = fieldValue?.replace(/[*-]/g, '');
  const containsUnderscores = formattedValue?.includes('_');

  let valueToDisplay;
  const valueIsComingFromDatabase =
    formattedValue?.length === 3 && !containsUnderscores;

  if (valueIsComingFromDatabase) {
    valueToDisplay = `***-***-${formattedValue}`;
  } else {
    valueToDisplay = rawValue;
  }

  if (hasNoStoredValue) {
    valueToDisplay = '';
  }

  useEffect(() => {
    if (valueIsComingFromDatabase) {
      setEncryptState(true);
    }
  }, [valueIsComingFromDatabase]);

  return (
    <PatternFormat
      {...restOfProps}
      allowEmptyFormatting
      customInput={InputText}
      format={encryptState ? ENCRYPTED_SIN_FORMAT : SIN_FORMAT}
      mask={EMPTY_MASK}
      value={valueToDisplay}
      onChange={(event) => {
        const { value } = event.target;
        if (
          value.split('').filter((char) => char === '_').length === 1 ||
          value === '***-***-___'
        ) {
          setEncryptState(false);
        }
      }}
    />
  );
}

export function EINInput<Name extends string>(
  props: Omit<PatternMaskProps, 'type' | 'format'> & { name: Name },
): JSX.Element {
  const { value: rawValue, ...restOfProps } = props;

  const [encryptState, setEncryptState] = useState(false);
  const fieldValue = rawValue?.toString();
  const hasNoStoredValue = fieldValue?.length === 0;
  const formattedValue = fieldValue?.replace(/[*-]/g, '');
  const containsUnderscores = formattedValue?.includes('_');

  let valueToDisplay;

  const valueIsComingFromDatabase =
    formattedValue?.length === 4 && !containsUnderscores;

  if (valueIsComingFromDatabase) {
    valueToDisplay = `**-***${formattedValue}`;
  } else {
    valueToDisplay = rawValue;
  }

  if (hasNoStoredValue) {
    valueToDisplay = '';
  }

  useEffect(() => {
    if (valueIsComingFromDatabase) {
      setEncryptState(true);
    }
  }, [valueIsComingFromDatabase]);

  return (
    <PatternFormat
      {...restOfProps}
      allowEmptyFormatting
      customInput={InputText}
      format={encryptState ? ENCRYPTED_EIN_FORMAT : EIN_FORMAT}
      mask={EMPTY_MASK}
      value={valueToDisplay}
      onChange={(event) => {
        const { value } = event.target;
        if (
          value.split('').filter((char) => char === '_').length === 1 ||
          value === '**-***____'
        ) {
          setEncryptState(false);
        }
      }}
    />
  );
}

export function PhoneNumberInput<Name extends string>(
  props: Omit<PatternMaskProps, 'type' | 'format'> & { name: Name },
): JSX.Element {
  return (
    <PatternFormat
      {...props}
      allowEmptyFormatting
      customInput={InputText}
      format={US_PHONE_FORMAT}
      startAdornment="+1"
      mask={EMPTY_MASK}
      type="tel"
    />
  );
}

export function PercentInput<Name extends string>(
  props: Omit<PatternMaskProps, 'type' | 'format'> & { name: Name },
): JSX.Element {
  return (
    <PatternFormat
      {...props}
      allowEmptyFormatting
      customInput={InputText}
      format="###"
    />
  );
}

export function PostalCodeInput<Name extends string>(
  props: Omit<PatternMaskProps, 'type' | 'format'> & { name: Name },
): JSX.Element {
  return (
    <PatternFormat
      {...props}
      allowEmptyFormatting
      customInput={InputText}
      format="######"
      mask={EMPTY_MASK}
    />
  );
}

export function MoneyInput(props: Omit<NumericMaskProps, 'type'>): JSX.Element {
  const { isFilled = false } = props;
  const style = isFilled
    ? {
        padding: 0,
        gap: '5px',
      }
    : {};

  return (
    <NumericFormat
      {...props}
      style={{ ...props.style, ...style }}
      customInput={InputText}
      decimalScale={2}
      startAdornment="$"
      endAdornment={isFilled ? '' : 'USD'}
      thousandSeparator
    />
  );
}

export function NumberInput(
  props: Omit<NumericMaskProps, 'type'>,
): JSX.Element {
  const decimalScale = props.decimalScale ?? 0;
  return (
    <NumericFormat
      {...props}
      customInput={InputText}
      decimalScale={decimalScale}
      thousandSeparator
    />
  );
}
